* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
}

svg path,
svg circle {
  color: unset;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content {
  width: 85vw;
  margin: 0 auto;
}

#global-modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

#global-modal {
  background: white;
  border-radius: 15px;
  padding: 20px;
}

:root {
  --primary-blue: #009ee0;
  --secondary-blue: #172983;
  --light-gray: #f8f8f8;
  --font-small: 14px;
  --font-normal: 16px;
  --font-medium: 20px;
  --font-large: 24px;
  --font-large: 30px;
  --font-larger: 36px;
}
